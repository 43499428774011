<template>
  <div class="register-wrapper">
    <header class="header">
      <img
        :src="require('@/assets/pics/logo_white.png')"
        style="width: 130px"
      />
    </header>
    <div class="user-register">
      <main class="register-box">
        <section class="title">
          {{ $t('serviceProvider.signUp.signUp') }}
        </section>
        <div class="content">
          <ValidationObserver ref="userRegisterFormRef">
            <section class="fields">
              <InputElement
                v-model="userRegisterForm.firstName"
                :placeholder="$t('serviceProvider.signUp.firstName')"
                validation-rules="required"
                validation-name="firstName"
              />
              <InputElement
                v-model="userRegisterForm.lastName"
                :placeholder="$t('serviceProvider.signUp.lastName')"
                validation-rules="required"
                validation-name="lastName"
              />
            </section>
            <section class="field">
              <InputElement
                v-model="userRegisterForm.email"
                :placeholder="$t('serviceProvider.client.email')"
                validation-rules="required|email"
                validation-name="email"
                type="email"
              />
            </section>
            <section class="fields">
              <InputElement
                v-model="userRegisterForm.password"
                type="password"
                :placeholder="$t('serviceProvider.signUp.password')"
                validation-rules="required|min:8"
                validation-name="password"
              />
              <InputElement
                v-model="userRegisterForm.confirmPassword"
                type="password"
                :placeholder="$t('serviceProvider.signUp.confirmPassword')"
                validation-rules="password:@password"
                validation-name="confirmPassword"
              />
            </section>
            <section class="field">
              <InputElement
                v-model="userRegisterForm.phoneNumber"
                :placeholder="$t('serviceProvider.client.phone')"
                validation-rules="required|phone"
                validation-name="phoneNumber"
              />
            </section>
            <ValidationProvider
              rules="requiredCustom"
              v-slot="{ errors, failed }"
            >
              <section class="toc">
                <CheckboxElement v-model="userRegisterForm.acceptToc" />
                <span class="text">
                  Ich habe die
                  <a
                    href="https://hoodty.devla.dev/docs/AGB.html"
                    target="_blank"
                    >AGBs</a
                  >
                  und
                  <a
                    href="https://hoodty.devla.dev/docs/Datenschutz.html"
                    target="_blank"
                    >Datenschutzerklärung</a
                  >
                  gelesen und akzeptiere diese
                </span>
              </section>
              <div v-if="failed" class="error">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </ValidationObserver>
        </div>

        <div>
          <ResponseAlert
            v-if="$store.getters.errorResponse.action == 'registerEndUser'"
            :response="$store.getters.errorResponse.data"
            class="mt-3"
          ></ResponseAlert>
        </div>

        <div class="footer">
          <ButtonElement
            @click="submitForm"
            class="submit-button"
            :loading="$store.getters.loading"
            orange
          >
            {{ $t('serviceProvider.signUp.signUp') }}
          </ButtonElement>
        </div>
        <div class="footer-sub">
          <span class="sub-text"
            >{{ $t('serviceProvider.signUp.alreadyHaveAnAccount') }}
            <ButtonElement
              :anchor="{ name: 'Login', params: { role: 'user' } }"
              link
              style="display: inline; font-size: 0.9rem"
            >
              {{ $t('serviceProvider.logIn.logIn') }}
            </ButtonElement>
          </span>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import InputElement from '../../../components/FormControl/InputElement.vue';
import ButtonElement from '../../../components/FormControl/ButtonElement.vue';
import CheckboxElement from '../../../components/FormControl/CheckboxElement.vue';
import store from '../../../store';
import router from '../../../router';
import ResponseAlert from '@/components/Alerts/ResponseAlert';

export default {
  name: 'UserRegister',
  setup() {
    const userRegisterFormRef = ref(null);
    const userRegisterForm = reactive({
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      acceptToc: false,
    });

    const submitForm = async () => {
      const valid = await userRegisterFormRef.value.validate();
      console.log('valid :>> ', valid);
      if (!valid) return;

      // submit here
      await store.dispatch('registerEndUser', userRegisterForm);

      router.push({
        name: 'Login',
        params: { role: 'user' },
        query: { registered: 1 },
      });
    };

    return {
      userRegisterForm,
      userRegisterFormRef,
      submitForm,
    };
  },
  created() {
    this.$store.commit('setErrorResponse', {});
  },
  components: {
    CheckboxElement,
    ButtonElement,
    InputElement,
    ResponseAlert,
  },
};
</script>

<style lang="scss" scoped>
.user-register {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;

  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../../../assets/pics/pexels-fauxels-3184292.png');
}
.header {
  position: fixed;
  top: 20px;
  left: 20px;
}
.fields {
  display: flex;
  margin-bottom: 1.2rem;

  > :first-child {
    margin-right: 20px;
  }
}
.content {
  margin-top: 2rem;
}
.field {
  margin-bottom: 1.2rem;
}
.register-box {
  background-color: white;
  border-radius: 10px;
  width: 460px;
  margin: 40px auto 0;
  padding: 32px 40px;
}
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.footer {
  margin-top: 1.2rem;
}
.footer-sub {
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  > :first-child {
    margin-right: 10px;
  }
}
.submit-button {
  width: 100%;
}
.text {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.4;
  color: $hty-dark-grey;
}
.sub-text {
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 1.4;
  color: $hty-dark-grey;
}
.toc {
  display: flex;
  > :first-child {
    margin-right: 0.5rem;
  }

  a {
    color: #e58413;
  }
}
</style>
